import { Flex, media, selectTheme, styled } from '@creditas-ui/react'

export const Wrapper = styled.section`
  background: ${({ dark }) =>
    selectTheme(dark ? 'colors.neutral.90' : 'colors.neutral.10')};
  width: 100%;
  display: ${({ isDisable }) => (isDisable ? 'none' : 'block')};
`

export const Container = styled.div`
  display: grid;
  margin-inline: auto;
  max-width: 1366px;
  grid-template-columns: 638px 1fr;
  grid-template-rows: auto;
  column-gap: 90px;
  grid-template-areas: 'image definition';

  ${media.down('4xl')} {
    padding: 72px 20px 10px;
    grid-template-columns: 1fr;
    grid-template-areas:
      'definition'
      'image';
  }

  ${media.between('5xl', '6xl')} {
    grid-template-columns: 1fr 390px;
  }

  ${media.between('5xl', '8xl')} {
    margin-inline: 71px;
  }
`

export const ImageContainer = styled.div`
  grid-area: image;
  max-width: 600px;

  align-self: center;

  ${media.down('4xl')} {
    margin-inline: -20px;
  }

  .image-lazy-app {
    width: 100%;
  }
`

export const TextContainer = styled(Flex)`
  flex-direction: column;
  grid-area: definition;
  justify-content: center;
  max-width: 650px;

  ${media.up('5xl')} {
    max-width: 496px;
  }
`

export const DownloadButton = styled.a`
  align-items: center;
  display: flex;
  justify-content: center;

  height: 52px;
  width: calc(50% - 6px);

  background-color: ${({ dark }) =>
    selectTheme(dark ? 'colors.neutral.90' : 'colors.neutral.10')};
  border: 1px solid
    ${({ dark }) =>
      selectTheme(dark ? 'color.neutral.6 s0' : 'colors.neutral.20')};
  border-radius: ${selectTheme('radius.4xl')};

  text-decoration: none;

  cursor: pointer;

  :not(:last-child) {
    margin-right: 12px;

    ${media.up('2xl')} {
      margin-right: 24px;
    }
  }

  :hover,
  :active,
  :focus {
    background-color: ${({ dark }) =>
      selectTheme(dark ? 'colors.neutral.80' : 'colors.neutral.15')};
  }

  path {
    fill: ${({ dark }) =>
      selectTheme(dark ? 'colors.neutral.10' : 'colors.neutral.90')};
  }

  ${media.up('2xl')} {
    height: 60px;
    width: 187px;
  }
`
