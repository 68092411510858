export const hasSource = source => {
  const windowGlobal = typeof window !== 'undefined' && window
  const params = windowGlobal?.location?.search || ''
  return (
    windowGlobal && new URLSearchParams(params).get('utm_source') === source
  )
}

export const hasMobileSource = () => hasSource('app-creditas')

export const getUtm = () => {
  if (typeof window !== 'undefined') {
    const queryString = window.location.search
    return new URLSearchParams(queryString)
  }
  return new URLSearchParams('')
}

export const hasUtm = () => {
  const utmList = getUtm()
  const source = utmList.get('utm_source')
  const medium = utmList.get('utm_medium')
  const campaign = utmList.get('utm_campaign')

  return (
    source === 'google' &&
    medium === 'ppc' &&
    campaign === '[mx][search][ar] Prestamo - Test Broad Match'
  )
}
