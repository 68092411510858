import React from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, SkeletonAvatar, Typography } from '@creditas-ui/react'

import { useAsset } from 'utils/useAssets'
import { bool, string } from 'prop-types'
import { AppleAppStore, GooglePlayStore } from 'icons'
import { track } from 'utils/tracking'
import { hasMobileSource } from 'utils/utm'
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component'
import { formatQueryParams } from 'utils/formatQueryParams'
import { useLocalFlag } from 'utils/featureFlags'
import {
  Container,
  DownloadButton,
  ImageContainer,
  TextContainer,
  Wrapper,
} from './downloadApp.styles'

const propTypes = {
  translationKey: string.isRequired,
  dark: bool,
}

// eslint-disable-next-line react/prop-types
const DownloadApp = ({ translationKey, dark, scrollPosition }) => {
  const { t } = useTranslation()
  const altText = t('downloadApp:imageAlt')
  const optionsEquity = useLocalFlag('autoEquityFlags')
  const assets = useAsset('landing/common')
  const mobileAppImage = assets.v1('download-app.webp')
  const buttons = [
    {
      href: formatQueryParams(t('downloadApp:linkGoogle')),
      text: t('downloadApp:buttonGoogle'),
      Icon: GooglePlayStore,
    },
    {
      href: formatQueryParams(t('downloadApp:linkApple')),
      text: t('downloadApp:buttonApple'),
      Icon: AppleAppStore,
    },
  ]

  const handleClick = (event, index) => {
    event.preventDefault()
    track({
      event: t(`downloadApp:event.${translationKey}`),
      category: 'download app | landingpage',
      action: 'descarga la app',
      label: buttons[index].text,
    })
    window.open(buttons[index].href, '_blank')
  }

  const isDisable = !optionsEquity.showDownLoadApp && translationKey === 'auto'

  if (hasMobileSource()) return <Wrapper />
  return (
    <Wrapper dark={dark} isDisable={isDisable}>
      <Container>
        <ImageContainer>
          <LazyLoadImage
            src={mobileAppImage}
            alt={altText}
            width="100%"
            placeholder={<SkeletonAvatar color="neutral.80" width={240} height={240} />}
            threshold={0}
            delayMethod="throttle"
            delayTime={300}
            scrollPosition={scrollPosition}
          />
        </ImageContainer>
        <TextContainer>
          <Typography
            component="h3"
            variant="exceptionMdBold"
            color={dark ? 'neutral.20' : 'neutral.80'}
            style={{ textTransform: 'uppercase' }}
          >
            {t('downloadApp:label')}
          </Typography>
          <Typography
            component="h2"
            variant={{
              '2xl': 'headingXlLight',
              _: 'headingLgLight',
            }}
            color={dark ? 'neutral.0' : 'neutral.90'}
            marginTop="16px"
          >
            {t('downloadApp:title')}
          </Typography>
          <Typography
            component="p"
            variant="bodyMdRegular"
            color={dark ? 'neutral.10' : 'neutral.90'}
            marginTop="24px"
          >
            {t('downloadApp:description')}
          </Typography>
          <Flex marginTop="24px">
            {buttons.map((button, index) => (
              <DownloadButton
                key={index}
                onClick={e => handleClick(e, index)}
                dark={dark}
              >
                <button.Icon />
                <Typography
                  variant={{
                    '2xl': 'bodyMdBold',
                    _: 'bodySmBold',
                  }}
                  component="span"
                  color={dark ? 'neutral.10' : 'neutral.90'}
                  marginLeft={{
                    '2xl': '16px',
                    _: '8px',
                  }}
                >
                  {button.text}
                </Typography>
              </DownloadButton>
            ))}
          </Flex>
        </TextContainer>
      </Container>
    </Wrapper>
  )
}

DownloadApp.propTypes = propTypes
export default trackWindowScroll(DownloadApp)
